<script>
    import {parse, formatDistanceToNowStrict} from "date-fns";
    import SimpleDialog from "@local/svelte/components/SimpleDialog.svelte";

    import {
        X,
        Clock,
        Search,
        Github,
        TableProperties,
        Code,
        RefreshCw,
        Settings,
        LoaderCircle,
        ArrowUp
    } from "lucide-svelte";
    import {onMount} from "svelte";

    const urlParams = new URLSearchParams(window.location.search);

    let paused = false;
    let pageRefreshInterval = 60 * 1000;
    let timeAgoRefreshInterval = 1 * 1000;
    let liveUpdateEnabled = (urlParams.get("live") !== "0");
    let tickSpeed = 100;
    let elapsedTime = 0;
    let searchQuery = (urlParams.get("query")) ? decodeURI(urlParams.get("query")) : "";
    let isUpdating = false;
    let isTyping = false;

    let awaitingUpdate = false;
    let scrolled = false;

    if (searchQuery) {
        elapsedTime = pageRefreshInterval;
    }

    export let domainListing;
    export let page = (urlParams.get("page")) ? parseInt(urlParams.get("page")) : 1;

    let hasAnotherPage = false;

    const fillViewPortWithAvailablePages = async () => {
        while (hasAnotherPage && loaderIsInViewport()) {
            await appendNextPageToDomainListing();
        }
    };
    const loaderIsInViewport = () => {
        const loader = document.querySelector("[data-load-more]");
        if (!loader) return false;
        const rect = loader.getBoundingClientRect();
        // check if the loader is in the viewport (even partially) by checking if the element's top is less than the window's height
        return rect.top <= window.innerHeight;
    };

    const updateHasAnotherPage = () => {
        hasAnotherPage = !!(domainListing && domainListing["hydra:view"] && domainListing["hydra:view"]["hydra:next"]);
    };

    let typingInterval = null;
    let typingElapsed = 0;
    let typingTimeout = 600;


    window.addEventListener("scroll", () => {
        paused = window.scrollY > 0;
        scrolled = window.scrollY > 0;
    });

    // refresh the display when tabbing back to the page
    window.addEventListener("focus", () => {
        elapsedTime = pageRefreshInterval;
    });

    // if the user clicks on a data-host link, update the page title to reflect the domain
    // otherwise, the page title will be the default
    document.addEventListener("click", (event) => {
        if (event.target.tagName === "A" && event.target.getAttribute("data-host")) {
            document.title = event.target.getAttribute("data-host");
        } else {
            document.title = "Domain Stats";
        }
    });

    // create an intersection observer to load more domains when the user scrolls to the bottom
    // do this onmount
    onMount(async () => {
        updateHasAnotherPage();
        await fillViewPortWithAvailablePages();
        const observer = new IntersectionObserver(async (entries) => {
            if (entries[0].isIntersecting) {
                await fillViewPortWithAvailablePages();
            }
        }, {threshold: 0.5});
        observer.observe(document.querySelector("[data-load-more]"));
    });

    // control + f should focus the search box
    document.addEventListener("keydown", (event) => {
        if (event.key === "f" && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            document.querySelector("[data-search]").focus();
        }
    });

    // focus the search box on load
    window.onload = () => {
        document.querySelector("[data-search]").value = searchQuery;
        document.querySelector("[data-search]").focus();
    };

    const startSearchTypingInterval = () => {
        if (typingInterval) {
            clearInterval(typingInterval);
            typingInterval = null;
            typingElapsed = 0;
        }

        isTyping = true;
        typingInterval = setInterval(async () => {
            typingElapsed += tickSpeed;
            if (typingElapsed >= typingTimeout) {
                clearInterval(typingInterval);
                typingInterval = null;
                typingElapsed = 0;
                window.scrollTo({top: 0, behavior: "smooth"});
                await updateDomainListing();
                document.querySelector("[data-search]").focus();
                isTyping = false;
            }
        }, tickSpeed);
    };

    const updateDomainListing = async (overwriteExisting = true) => {
        if (overwriteExisting) {
            page = (urlParams.get("page")) ? parseInt(urlParams.get("page")) : 1;
        }
        isUpdating = true;
        let readEnpdoint = `/api/domains?page=${page}`;
        if (searchQuery !== "") {
            let urlEncodedSearchQuery = encodeURIComponent(searchQuery);
            readEnpdoint += `&deep_search=${urlEncodedSearchQuery}`;
        }
        let readResponse = await fetch(readEnpdoint, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        let domainListingResponse = await readResponse.json();
        if (overwriteExisting) {
            domainListing = domainListingResponse;
        } else {
            domainListing["hydra:member"] = domainListing["hydra:member"].concat(domainListingResponse["hydra:member"]);
        }
        domainListing["hydra:view"] = domainListingResponse["hydra:view"] || [];
        isUpdating = false;

        updateHasAnotherPage();
        await fillViewPortWithAvailablePages();
        return domainListing;
    };

    const appendNextPageToDomainListing = async () => {
        if (!domainListing || !domainListing["hydra:view"] || !domainListing["hydra:view"]["hydra:next"]) {
            return;
        }

        page++;
        await updateDomainListing(false);
    }

    // real time updates
    setInterval(async () => {
        if (!liveUpdateEnabled || paused || isTyping) {
            return;
        }

        elapsedTime += tickSpeed;
        if (elapsedTime < pageRefreshInterval) return;

        if (!awaitingUpdate) {
            awaitingUpdate = true;
            await updateDomainListing();
            elapsedTime = 0;
            awaitingUpdate = false;
        }
    }, tickSpeed);

    const parseDatabaseDateAsTimeAgo = (date) => {
        if (!date) return "";
        // expects to receive in format "2024-05-16T17:56:51+00:00"
        const parsedDate = parse(date, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date());
        return formatDistanceToNowStrict(parsedDate, {addSuffix: false});
    };

    setInterval(() => {
        const timeAgoElements = document.querySelectorAll("[data-updated-at]");
        timeAgoElements.forEach((element) => {
            const updatedAt = element.getAttribute("data-updated-at");
            element.innerText = parseDatabaseDateAsTimeAgo(updatedAt);
        });
    }, timeAgoRefreshInterval);

    let dialog;

    const httpSeverityColors = (stateJSON) => {
        const statusCode = stateJSON?.status?.http?.code;
        if (!statusCode || statusCode >= 400) {
            return "text-white hover:text-white bg-severity-high";
        }

        if (
            stateJSON?.status?.http?.found_url &&
            !stateJSON?.status?.http?.found_url.startsWith("https")
        ) {
            return "text-white hover:text-white bg-severity-medium";
        }

        if (stateJSON?.status?.http?.redirected) {
            return "text-white hover:text-white bg-severity-info";
        }
        return "text-white hover:text-white bg-severity-low";
    };

    const httpsSeverityColors = (stateJSON) => {
        const statusCode = stateJSON?.status?.https?.code;
        if (!statusCode || statusCode >= 400) {
            return "text-white hover:text-white bg-severity-high";
        }

        if (stateJSON?.status?.https?.redirected) {
            return "text-white hover:text-white bg-severity-info";
        }
        return "text-white hover:text-white bg-severity-low";
    };

    const sslSeverityColors = (stateJSON) => {
        if (!stateJSON) {
            return "text-white bg-severity-high";
        }
        if (!stateJSON?.ssl?.remaining_days || !stateJSON?.ssl?.is_valid || stateJSON?.ssl?.expired) {
            return "text-white bg-severity-high";
        } else if (stateJSON?.ssl?.expiring_soon) {
            return "text-white bg-severity-medium";
        } else {
            return "text-white bg-severity-low";
        }
    };

    const cardSeverityColors = (stateJSON) => {
        const severity = stateJSON?.severity;

        if (severity >= 2) {
            return {
                text: "text-severity-high",
                border: "border-t-severity-high",
            };
        } else if (severity >= 1 || stateJSON?.ssl?.expiring_soon) {
            return {
                text: "text-severity-medium",
                border: "border-t-severity-medium",
            };
        } else {
            return {
                text: "text-severity-low",
                border: "border-t-white",
            };
        }
    };

    const onInputChange = (event) => {
        searchQuery = event.target.value;
        console.log(searchQuery);
    }

</script>

<SimpleDialog bind:this={dialog}/>
<div class="flex flex-row justify-center items-center gap-2 sticky top-0 bg-page z-50">
    <div class="relative w-full h-1 bg-slate-200">
        <div class="{liveUpdateEnabled ? '' : 'hidden'} absolute top-0 left-0 h-1 {paused ? 'bg-amber-500' : 'bg-sky-500'} transition-all duration-100"
             style="width: min(100%,{elapsedTime/pageRefreshInterval*100}%)"></div>
    </div>
</div>
<div class="flex flex-row justify-between items-center gap-2 p-2 mt-2 m-auto w-full max-w-screen-readable sticky top-1 bg-page z-50">
    <!-- search box -->
    <label class="inline-flex items-center w-full shadow rounded {(isUpdating || searchQuery.length > 0) ? 'bg-slate-700' : 'bg-slate-400'}">
        <button class="text-sky-500 ml-5 mr-5 {(searchQuery.length > 0 && !isUpdating) ? '' : 'hidden'}" on:click={(event) => {
            event.preventDefault();
            event.stopPropagation();
            searchQuery = "";
            let url = new URL(window.location);
            url.searchParams.delete("query");
            window.history.replaceState({}, "", url);
            document.querySelector("[data-search]").value = "";
            document.querySelector("[data-search]").focus();
            isUpdating = true;
            updateDomainListing();
            window.scrollTo({top: 0, behavior: "smooth"});
        }}>
            <X class="cursor-pointer"/>
        </button>
        <Search class="text-slate-300 ml-5 mr-5 {(isUpdating || searchQuery.length > 0) ? 'hidden' : ''}"/>
        <RefreshCw class="text-sky-500  ml-5 mr-5 animate-spin {(isUpdating) ? '' : 'hidden'}"/>
        <input data-search type="text"
               class="peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer p-2 w-full placeholder-slate-300 rounded-tr rounded-br"
               on:keyup={(event) => {
                 if (searchQuery === event.target.value) {
                   return;
                 }
             searchQuery = event.target.value;
             // update the URL's query string
             let url = new URL(window.location);
             url.searchParams.set("query", searchQuery);
             window.history.replaceState({}, "", url);

            // blur on escape
            if (event.key === "Escape") {
              event.target.blur();
              return;
            }

            // ignore modifier keys
            if (event.key === "Shift" || event.key === "Alt") {
              return;
            }
            // disallow search of 1 or 2 character length
            if (event.target.value.length < 3 && event.target.value.length > 0) {
              return;
            }

            isUpdating = true;
            startSearchTypingInterval();

            // immediately search if enter key is pressed
            if (isUpdating && event.key === "Enter") {
              typingElapsed = 9000;
            }
        }} placeholder="integratedwebworks.com, 199.250.203.190, InMotion 6">
    </label>
    <label class="inline-flex items-center cursor-pointer ml-auto text-nowrap">
        <input type="checkbox" value="" class="sr-only peer" on:change={(event) => {
            liveUpdateEnabled = event.target.checked;
            if (event.target.checked) {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }} bind:checked={liveUpdateEnabled}>
        <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all {paused ? 'peer-checked:bg-amber-500' : 'peer-checked:bg-sky-500' }"></div>
        <span class="ms-3 text-sm font-medium text-slate-400 min-w-12">{paused && liveUpdateEnabled ? 'Paused' : 'Live'}</span>
    </label>
    <a href="/iww-admin">
        <Settings class="text-slate-400 hover:text-sky-400"/>
    </a>
</div>
<div
        class="p-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 m-auto w-full max-w-screen-readable"
>
    {#each domainListing["hydra:member"] as domain}
        <article
                class="min-h-[160px] p-3 bg-white border-t-4 {cardSeverityColors(domain?.stateJSON)[
        'border'
      ]} rounded-md shadow"
                data-domain={domain?.host ?? "unknown"}
                data-severity={domain?.severity ?? 0}
        >
            <div
                    class="flex flex-nowrap gap-3 w-full items-center justify-between text-slate-400 text-sm"
            >
                <div class="flex gap-2 items-center text-xs uppercase mr-auto">
                    <Clock class="w-4 h-4"/>
                    <span
                            class="{domain?.stateJSON ? '' : 'hidden'} "
                            data-updated-at={domain?.updatedAt}
                    >{parseDatabaseDateAsTimeAgo(domain?.updatedAt)}</span
                    >
                    <span class={domain?.stateJSON ? "hidden" : ""}
                    >Awaiting initial scan</span
                    >
                </div>
                <span
                        class="{domain?.githubURL
            ? ''
            : 'hidden'} hover:cursor-pointer hover:text-blue-400 transition-all"
                >
          <a href={domain?.githubURL ?? "#nolink"} target="_blank"
          ><Github class="w-4 h-4"/></a
          >
        </span>
                <div
                        class="group hover:cursor-pointer hover:text-blue-400 transition-all relative"
                >
                    <div
                            class="group-hover:block bg-slate-100 text-slate-600 group-focus-within:block hidden absolute top-[100%] right-[-3.5rem] p-3 shadow rounded text-sm max-h-[135px] max-w-[300px] overflow-auto"
                    >
                        {#if !domain?.stateJSON?.dns?.A}
                            <div class="text-nowrap text-slate-400">DNS Not Available</div>
                        {:else}
                            <!--{#if domain?.stateJSON?.dns?.A}-->
                            <!--  {#each domain?.stateJSON?.dns?.A as record}-->
                            <!--    <div-->
                            <!--      class="text-md text-nowrap grid grid-cols-[3rem,8fr] w-max"-->
                            <!--    >-->
                            <!--      <strong class="">A</strong>-->
                            <!--      <span class="text-nowrap">{record.ip}</span>-->
                            <!--    </div>-->
                            <!--  {/each}-->
                            <!--{/if}-->
                            {#if domain?.stateJSON?.dns?.NS}
                                {#each domain?.stateJSON?.dns?.NS as record}
                                    <div
                                            class="text-md text-nowrap grid grid-cols-[3rem,8fr] w-max"
                                    >
                                        <strong class="">NS</strong>
                                        <span class="text-nowrap">{record.target}</span>
                                    </div>
                                {/each}
                            {/if}
                            {#if domain?.stateJSON?.dns?.MX}
                                {#each domain?.stateJSON?.dns?.MX as record}
                                    <div
                                            class="text-md text-nowrap grid grid-cols-[3rem,8fr] w-max"
                                    >
                                        <strong class="">MX</strong>
                                        <span class="text-nowrap">{record.target}</span>
                                    </div>
                                {/each}
                            {/if}
                        {/if}
                    </div>
                    <TableProperties class="w-4 h-4 rotate-180"/>
                </div>
                <button class="hover:cursor-pointer hover:text-blue-400 transition-all" on:click={() => {
                        let prettyJSON = JSON.stringify(domain?.stateJSON, null, 4);
                        dialog.setTitle(domain?.host ?? "Unknown Domain");
                        dialog.setContent(prettyJSON ?? "No Data Available");
                        dialog.openDialog();
                    }}>
                    <Code class="w-4 h-4"/>
                </button>
                <button class="hover:cursor-pointer hover:text-blue-400 transition-all"
                        on:click={async (event) => {
                        let icon = event.target;
                        icon = (icon.tagName === "svg") ? icon : icon.querySelector("svg") ?? icon.closest("svg");

                          let refreshEnpdoint = `/api/domains/${domain.id}/refresh`;
                          let readEnpdoint = `/api/domains/${domain.id}`;
                          icon.classList.add("animate-spin");

                          let refreshResponse = await fetch(refreshEnpdoint, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          });

                          let readResponse = await fetch(readEnpdoint, {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                            },
                          });

                          let jsonData = await readResponse.json();
                          domain = jsonData;

                          // update the time ago
                          let timeAgoElement =
                            event.target.parentElement.parentElement.querySelector(
                              "[data-updated-at]",
                            );
                          if (timeAgoElement) {
                              timeAgoElement.innerText = parseDatabaseDateAsTimeAgo(
                                jsonData.updatedAt,
                              );
                          }

                          icon.classList.remove("animate-spin");
                        }}
                >
                    <RefreshCw class="w-4 h-4"/>
                </button>
            </div>
            <h2 class="mt-1">
                <a
                        class="text-slate-600 hover:text-sky-500"
                        href="//{domain['host']}"
                        target="_blank"
                >
                    {domain["host"]}
                </a>
            </h2>
            <h3
                    class="{
                    ((domain?.stateJSON?.ip_error) && 'text-red-500') ||
                    (domain?.stateJSON?.dns?.A && domain?.stateJSON?.dns?.A[0]?.ip && 'text-slate-500') ||
                    'text-slate-300'
                } leading-tight text-xs">
                {(domain?.stateJSON?.dns?.A && domain?.stateJSON?.dns?.A[0]?.ip) ??
                "Unknown IP Address"}
            </h3>
            <h3 class="text-slate-400 leading-tight text-sm mt-2">
                <a
                        href={domain?.server?.url ?? "#nolink"}
                        data-host={domain?.server?.name + ' (' + domain['host'] + ')'}
                        class="hover:cursor-pointer {domain?.server?.url
            ? 'text-slate-600 hover:text-sky-500'
            : 'pointer-events-none'}"
                        target="_blank"
                >
                    {domain?.server?.name ?? 'Unknown Server'}
                </a>
            </h3>
            <div
                    class="{domain?.stateJSON
          ? 'hidden'
          : ''} flex gap-6 flex-nowrap w-full items-center justify-center leading-tight mt-5 text-slate-300 text-4xl"
            >
                <i class="fa-solid fa-hourglass"></i>
                <span>No Data Available</span>
            </div>
            <div
                    class="{domain?.stateJSON
          ? ''
          : 'hidden'} flex flex-nowrap gap-3 w-full items-center justify-center text-white text-[1.25rem] font-bold leading-tight mt-2"
            >
                <a
                        href={domain?.stateJSON?.status?.http?.found_url ??
            "http://" + domain["host"]}
                        target="_blank"
                        class="text-xs flex flex-col gap-0 items-center {httpSeverityColors(
            domain['stateJSON'],
          )} px-2 py-1 rounded uppercase w-1/3"
                >
                    <span>http</span>
                    <span>{domain?.stateJSON?.status?.http?.code ?? "unknown"}</span>
                </a>
                <a
                        href={domain?.stateJSON?.status?.https?.found_url ??
            "https://" + domain["host"]}
                        target="_blank"
                        class="text-xs flex flex-col gap-0 items-center {httpsSeverityColors(
            domain['stateJSON'],
          )} px-2 py-1 rounded uppercase w-1/3"
                >
                    <span>https</span>
                    <span>{domain?.stateJSON?.status?.https?.code ?? "unknown"}</span>
                </a>
                <div
                        class="text-xs flex flex-col gap-0 items-center {sslSeverityColors(
            domain['stateJSON'],
          )} px-2 py-1 rounded uppercase w-1/3"
                >
                    <span>SSL</span>
                    <span>
            {domain?.stateJSON?.ssl?.remaining_days
                ? domain.stateJSON?.ssl?.remaining_days + " d"
                : "?"}
          </span>
                </div>
            </div>
        </article>
    {/each}
</div>
<!-- load more button, but ony if domainListing['hydra:view']['hydra:next'] exists -->
<div data-load-more
     class="flex flex-row justify-center font-bold items-center text-center gap-2 p-10 w-full m-auto max-w-screen-readable">
    {#if hasAnotherPage}
        <LoaderCircle size={40} class="text-slate-400 animate-spin"/>
    {/if}
</div>
{#if scrolled}
    <!-- if paused and live update is enabled, the arrow should say resume on it, otherwise just use the arrow -->
    <button
            class="fixed bottom-4 right-4 p-2 bg-amber-500 text-white rounded-full shadow cursor-pointer"
            on:click={() => {
                window.scrollTo({top: 0, behavior: "smooth"});
            }}
    >
        <span class="flex justify-center items-center">
            <ArrowUp class="w-6 h-6"/>
            <span class="{(paused && liveUpdateEnabled) ? '' : 'hidden'} text-xs font-bold uppercase pr-1">Resume</span>
        </span>
    </button>

{/if}
